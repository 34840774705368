import React from "react"
import cx from "classnames"

import * as styles from "~styles/atoms/text.module.scss"
import { getTextWithFormattedFormulas } from "~utility/Sink"

export function RteText({
    content,
    variant = "div",
    className,
    gutterBottom = false,
    hasLine = false,
    style,
}) {
    const ElementTag = variant

    return (
        <ElementTag
            className={cx(
                className,
                styles.root,
                styles[variant],
                {
                    [styles.small]: style === "small",
                },
                {
                    [styles.hasLine]: hasLine,
                },
                {
                    [styles.gutterBottom]: gutterBottom,
                },
                {
                    [styles.largeGutterBottom]: gutterBottom === "large",
                }
            )}
            dangerouslySetInnerHTML={{
                __html: getTextWithFormattedFormulas(content),
            }}
        />
    )
}
