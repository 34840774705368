import React from "react"
import cx from "classnames"

import * as styles from "~styles/atoms/text.module.scss"

export function Text({
    variant = "p",
    className,
    gutterBottom = false,
    hasLine = false,
    children,
    style,
}) {
    const ElementTag = variant

    return (
        <ElementTag
            className={cx(
                className,
                styles[variant],
                {
                    [styles.small]: style === "small",
                },
                {
                    [styles.hasLine]: hasLine,
                },
                {
                    [styles.gutterBottom]: gutterBottom,
                }
            )}
        >
            {children}
        </ElementTag>
    )
}
