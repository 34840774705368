import React from "react"
import { Headline } from "~components/atoms/Headline"
import { RteText } from "~components/atoms/RteText"
import { root } from "~styles/elements/teaser/contact-box.module.scss"
import { translate } from "~utility/Sink"

export function ContactBox({ language, item }) {
    return (
        <div className={root}>
            <Headline hasLine>{item.headline}</Headline>
            <strong>{item.subline}</strong>
            {item.address && <RteText content={item.address} />}
            <p>
                {item.name && (
                    <span>
                        {item.name}
                        <br />
                    </span>
                )}
                {item.phone && (
                    <span>
                        Tel. {item.phone}
                        <br />
                    </span>
                )}
                {item.fax && (
                    <span>
                        Fax {item.fax}
                        <br />
                    </span>
                )}
                {item.email && (
                    <a href={"mailto:" + item.email}>
                        {translate("contact_box.write_email", language)}
                    </a>
                )}
            </p>
        </div>
    )
}
