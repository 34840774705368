import React from "react"
import { graphql } from "gatsby"
import { DefaultLayout } from "~components/layout/Default"
import { SEO } from "~components/Seo"
import { HeavyTypoHeader } from "~components/elements/HeavyTypoHeader"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { RteText } from "~components/atoms/RteText"
import * as styles from "~styles/templates/job.module.scss"
import * as buttonStyles from "~styles/atoms/button.module.scss"
import { ContactBox } from "~components/elements/ContactBox"
import { Headline } from "~components/atoms/Headline"
import { IconTextList } from "~components/elements/IconTextList"
import cx from "classnames"

const JobTemplate = ({ data, pageContext }) => {
    const job = data.jobs.results.find(
        el => el.jobPostingId === pageContext.uid
    )
    const page = data.page.edges[0].node.data
    const contact = data.contact.edges.find(item => item.node.lang === "de-de")
        .node.data

    return (
        <DefaultLayout
            activeDocMeta={{
                lang: "de-de",
                alternateLanguages: [
                    { type: "home", lang: "en-gb", url: "/en" },
                    { type: "home", lang: "zh-cn", url: "/zh" },
                ],
            }}
            title={job.title}
        >
            <SEO title={job.title} />

            <div className={styles.header}>
                <HeavyTypoHeader
                    headline={job.title}
                    subline="Future Starts Here"
                    gutterBottom={false}
                    text={page.body[0].primary.text.html}
                />
            </div>

            <GridRow width="content">
                <GridColumn s={12} m={8} l={8} largeSpacing>
                    <ContentSection>
                        <Headline variant="h2">
                            {
                                job.config
                                    .softgarden_STEPSTONE_LIQUID_DESIGN_TASKS_adText_0_title[0]
                            }
                        </Headline>
                        <RteText
                            content={
                                job.config
                                    .softgarden_STEPSTONE_LIQUID_DESIGN_TASKS_adText_0_content[0]
                            }
                        />
                        <Headline variant="h2">
                            {
                                job.config
                                    .softgarden_STEPSTONE_LIQUID_DESIGN_PROFILE_adText_0_title[0]
                            }
                        </Headline>
                        <RteText
                            content={
                                job.config
                                    .softgarden_STEPSTONE_LIQUID_DESIGN_PROFILE_adText_0_content[0]
                            }
                        />
                        <Headline variant="h2">
                            {page.body[1].primary.headline.text}
                        </Headline>
                        <IconTextList items={page.body[1].items} />
                        <a
                            target="_blank"
                            href={job.applyOnlineLink}
                            className={cx(
                                styles.detailFormLink,
                                buttonStyles.root,
                                buttonStyles.withArrow
                            )}
                        >
                            Zur Online-Bewerbung
                        </a>
                        <RteText content={page.body[2].primary.text.html} />
                    </ContentSection>
                </GridColumn>
                <GridColumn s={12} m={4} l={4} largeSpacing>
                    <ContentSection>
                        <ContactBox
                            language="de-de"
                            item={{
                                headline: contact.name.text,
                                subline: contact.title.text,
                                address: contact.address.html,
                                phone: contact.telephone.text,
                                email: contact.email.text,
                            }}
                        />
                    </ContentSection>
                </GridColumn>
            </GridRow>
        </DefaultLayout>
    )
}

export default JobTemplate

export const query = graphql`
    query JobDetail {
        contact: allPrismicContact(
            filter: { data: { role: { eq: "Human Resources" } } }
        ) {
            edges {
                node {
                    lang
                    data {
                        address {
                            html
                        }
                        email {
                            text
                        }
                        name {
                            text
                        }
                        role
                        telephone {
                            text
                        }
                        title {
                            text
                        }
                    }
                }
            }
        }
        page: allPrismicPage(
            filter: { data: { identifier: { text: { eq: "job-detail" } } } }
        ) {
            edges {
                node {
                    lang
                    data {
                        meta_description {
                            text
                        }
                        name {
                            text
                        }
                        title {
                            text
                        }
                        body {
                            ... on PrismicPageBodyVideo {
                                id
                                primary {
                                    headline {
                                        text
                                    }
                                    layout1
                                    subline {
                                        text
                                    }
                                    text {
                                        html
                                    }
                                    video {
                                        thumbnail_url
                                        embed_url
                                        html
                                        title
                                        provider_name
                                    }
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyAccordion {
                                id
                                items {
                                    headline {
                                        text
                                    }
                                    text {
                                        html
                                    }
                                    is_opened
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyTiles {
                                id
                                slice_type
                                items {
                                    headline {
                                        text
                                    }
                                    layout
                                    link {
                                        type
                                        uid
                                        lang
                                        document {
                                            ... on PrismicPage {
                                                data {
                                                    parent_page {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    subline {
                                        text
                                    }
                                }
                            }
                            ... on PrismicPageBodyDataPlugin {
                                id
                                primary {
                                    plugin_id {
                                        text
                                    }
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyProductsGrid {
                                id
                                slice_type
                            }
                            ... on PrismicPageBodyImageTeaserSlider {
                                id
                                primary {
                                    headline {
                                        text
                                    }
                                }
                                items {
                                    layout
                                    text {
                                        html
                                    }
                                    link {
                                        uid
                                        type
                                        lang
                                        document {
                                            ... on PrismicPage {
                                                data {
                                                    parent_page {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    image {
                                        url
                                    }
                                    headline {
                                        text
                                    }
                                    button_label {
                                        text
                                    }
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyQuoteSlider {
                                id
                                items {
                                    text {
                                        text
                                    }
                                    subline {
                                        text
                                    }
                                    image {
                                        url
                                        thumbnails {
                                            mobile {
                                                url
                                            }
                                        }
                                    }
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyHistoryEvents {
                                id
                                slice_type
                                primary {
                                    headline {
                                        text
                                    }
                                    subline {
                                        text
                                    }
                                    text {
                                        text
                                    }
                                }
                            }
                            ... on PrismicPageBodyTextBanner {
                                id
                                slice_type
                                primary {
                                    headline {
                                        text
                                    }
                                    layout
                                    subline {
                                        text
                                    }
                                }
                                items {
                                    icon {
                                        url
                                    }
                                    text {
                                        html
                                    }
                                    headline {
                                        text
                                    }
                                }
                            }
                            ... on PrismicPageBodyTextBlock {
                                id
                                primary {
                                    text {
                                        html
                                    }
                                    layout
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyTeaserGrid {
                                id
                                slice_type
                                primary {
                                    headline {
                                        text
                                    }
                                    layout
                                }
                                items {
                                    text {
                                        html
                                    }
                                    link {
                                        uid
                                        type
                                        lang
                                        document {
                                            ... on PrismicPage {
                                                data {
                                                    parent_page {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    image {
                                        url
                                    }
                                    icon {
                                        url
                                    }
                                    headline {
                                        text
                                    }
                                    subline {
                                        text
                                    }
                                    button_label {
                                        text
                                    }
                                }
                            }
                            ... on PrismicPageBodyHeavyTypoHeader {
                                id
                                primary {
                                    subline {
                                        text
                                    }
                                    layout
                                    image {
                                        url
                                    }
                                    headline {
                                        text
                                    }
                                    text {
                                        html
                                    }
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyTextAndMenu {
                                id
                                primary {
                                    text {
                                        html
                                    }
                                    headline {
                                        text
                                    }
                                }
                                slice_type
                                items {
                                    link {
                                        uid
                                        type
                                        document {
                                            ... on PrismicPage {
                                                data {
                                                    parent_page {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    label {
                                        text
                                    }
                                }
                            }
                            ... on PrismicPageBodyInteractiveVideo {
                                id
                                slice_type
                                primary {
                                    video {
                                        document {
                                            ... on PrismicInteractiveVideo {
                                                id
                                                data {
                                                    video_mobile {
                                                        url
                                                    }
                                                    video_desktop {
                                                        url
                                                    }
                                                    titles {
                                                        subline {
                                                            text
                                                        }
                                                        link {
                                                            uid
                                                            type
                                                            lang
                                                            document {
                                                                ... on PrismicPage {
                                                                    data {
                                                                        parent_page {
                                                                            uid
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        headline {
                                                            text
                                                        }
                                                        fade_out_at
                                                        fade_in_at
                                                    }
                                                    markers {
                                                        mobile_y_position
                                                        link {
                                                            uid
                                                            type
                                                            lang
                                                            document {
                                                                ... on PrismicPage {
                                                                    data {
                                                                        parent_page {
                                                                            uid
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        mobile_x_position
                                                        subline {
                                                            text
                                                        }
                                                        layout
                                                        icon {
                                                            url
                                                        }
                                                        headline {
                                                            text
                                                        }
                                                        fade_out_at
                                                        fade_in_at
                                                        desktop_y_position
                                                        desktop_x_position
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            ... on PrismicPageBodyCountingNumbers {
                                id
                                primary {
                                    headline {
                                        text
                                    }
                                    layout
                                }
                                slice_type
                                items {
                                    text {
                                        text
                                    }
                                    suffix {
                                        text
                                    }
                                    prefix {
                                        text
                                    }
                                    number
                                    icon {
                                        url
                                    }
                                }
                            }
                            ... on PrismicPageBodyTextTeaser {
                                id
                                primary {
                                    text {
                                        html
                                    }
                                    link {
                                        uid
                                        type
                                        url
                                        document {
                                            ... on PrismicPage {
                                                data {
                                                    parent_page {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    layout
                                    button_label {
                                        text
                                    }
                                    headline {
                                        text
                                    }
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyLargeImageTeaser {
                                id
                                primary {
                                    text {
                                        html
                                    }
                                    link {
                                        uid
                                        type
                                        lang
                                        document {
                                            ... on PrismicPage {
                                                data {
                                                    parent_page {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    layout
                                    image {
                                        url
                                    }
                                    icon {
                                        url
                                    }
                                    headline {
                                        text
                                    }
                                    button_label {
                                        text
                                    }
                                }
                                slice_type
                            }
                            ... on PrismicPageBodyTextBanner {
                                id
                                primary {
                                    subline {
                                        text
                                    }
                                    layout
                                    headline {
                                        text
                                    }
                                }
                                items {
                                    text {
                                        text
                                    }
                                    icon {
                                        url
                                    }
                                    headline {
                                        text
                                    }
                                }
                            }
                            ... on PrismicPageBodyImageTeaserGroup {
                                id
                                slice_type
                                items {
                                    image {
                                        url
                                    }
                                    link {
                                        type
                                        uid
                                        id
                                        lang
                                        document {
                                            ... on PrismicPage {
                                                data {
                                                    parent_page {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    subline {
                                        text
                                    }
                                    text {
                                        html
                                    }
                                }
                                primary {
                                    headline {
                                        text
                                    }
                                    layout
                                    text {
                                        html
                                    }
                                }
                            }
                            ... on PrismicPageBodyQuote {
                                id
                                slice_type
                                primary {
                                    text {
                                        text
                                    }
                                    subline {
                                        text
                                    }
                                    image {
                                        url
                                        thumbnails {
                                            mobile {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        jobs: dataJson {
            results {
                title
                jobPostingId
                applyOnlineLink
                config {
                    jobcategory
                    softgarden_workTime
                    softgarden_workTime_de
                    softgarden_workTime_en
                    softgarden_workExperience
                    sg_career_level
                    softgarden_type
                    softgarden_employmentType
                    softgarden_STEPSTONE_LIQUID_DESIGN_CONTACTS_adText_0_content
                    softgarden_STEPSTONE_LIQUID_DESIGN_CONTACTS_adText_0_title
                    softgarden_STEPSTONE_LIQUID_DESIGN_IMAGE_adText_0_content
                    softgarden_STEPSTONE_LIQUID_DESIGN_IMAGE_adText_0_title
                    softgarden_STEPSTONE_LIQUID_DESIGN_OFFER_adText_0_content
                    softgarden_STEPSTONE_LIQUID_DESIGN_OFFER_adText_0_title
                    softgarden_STEPSTONE_LIQUID_DESIGN_PROFILE_adText_0_content
                    softgarden_STEPSTONE_LIQUID_DESIGN_PROFILE_adText_0_title
                    softgarden_STEPSTONE_LIQUID_DESIGN_TASKS_adText_0_content
                    softgarden_STEPSTONE_LIQUID_DESIGN_TASKS_adText_0_title
                    sg_working_hours
                    sg_location
                    sg_job_title
                    ProjectGeoLocationLat
                    ProjectGeoLocationLong
                    ProjectGeoLocationName
                    ProjectGeoLocationZip
                    ProjectGeoLocationCity
                }
            }
        }
    }
`
