import React from "react"
import { Arrow } from "~components/atoms/Arrow"
import { RteText } from "~components/atoms/RteText"
import Logo from "~images/logo/c.svg"
import * as styles from "~styles/elements/header/_heavy-typo.module.scss"
import cx from "classnames"

export const HeavyTypoHeader = ({
    subline,
    headline,
    image,
    text = false,
    showLogo = true,
    gutterBottom = true,
    className,
}) => {
    return (
        <header
            className={cx(
                styles.root,
                {
                    [styles.gutterBottom]: gutterBottom,
                },
                className
            )}
        >
            {subline && <h4>{subline}</h4>}
            <h1>{headline}</h1>
            {text && <RteText content={text} />}
            <Arrow />
            {image && (
                <div className={styles.img}>
                    <img src={image} alt={headline} />
                    {showLogo && (
                        <img className={styles.logo} src={Logo} alt="" />
                    )}
                </div>
            )}
        </header>
    )
}
