import React from "react"
import cx from "classnames"

import * as styles from "~styles/atoms/headline.module.scss"

export function Headline({
    variant = "h3",
    className,
    hasLine = false,
    children,
    gutterBottom = true,
}) {
    const ElementTag = variant

    return (
        <ElementTag
            className={cx(
                className,
                styles.root,
                styles[variant],
                {
                    [styles.hasLine]: hasLine,
                },
                {
                    [styles.gutterBottom]: gutterBottom,
                },
                {
                    [styles.gutterBottomIsLarge]: gutterBottom === "large",
                }
            )}
        >
            {children}
        </ElementTag>
    )
}
