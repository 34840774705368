import React from "react"
import { root } from "~styles/atoms/content-section.module.scss"
import cx from "classnames"

export function ContentSection({ children, className, ...props }) {
    return (
        <section {...props} className={cx(root, className)}>
            {children}
        </section>
    )
}
