import React from "react"
import * as styles from "~styles/atoms/arrow.module.scss"
import ArrowRedIcon from "~images/icon/icon-arrow-red.svg"

import cx from "classnames"

export const Arrow = ({ gutterBottom = true }) => {
    return (
        <div
            className={cx(styles.arrow, {
                [styles.gutterBottom]: gutterBottom,
            })}
        >
            <img src={ArrowRedIcon} alt="" />
        </div>
    )
}
