import React from "react"
import * as styles from "~styles/elements/list/icon-text-list.module.scss"
import { Text } from "~components/atoms/Text"

export function IconTextList({ items }) {
    return (
        <div className={styles.items}>
            {items.map((item, i) => (
                <div key={i} className={styles.item}>
                    {item.icon.url && (
                        <div className={styles.icon}>
                            <img src={item.icon.url} />
                        </div>
                    )}
                    <div>
                        <Text>{item.headline.text}</Text>
                    </div>
                </div>
            ))}
        </div>
    )
}
